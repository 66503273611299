<template>
  <div class="article-details">
     <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-d-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/brandDynamic' }">{{$t('brandNews')}}</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div v-if="info" class="article">
      <div class="title">{{info.articleTitle}}</div>
      <div class="subtitle">{{info.subtitle}}</div>
      <div class="author">{{info.articleAuthor}}</div>
      <div v-html="info.articleDetails" class="content"></div>
    </div>
  </div>

</template>
<script>
import { articleDetails } from '@/api/academic'

export default {
  data() {
    return {
      info: null
    }
  },
  created() {
    this.getDetails(this.$route.query.id)
  },
  methods: {
    getDetails(id) {
      articleDetails({ id }).then(res => {
        if (res.code === 10000) {
          this.info = res.data
        }
      })
    }
  }
}
</script>
<style lang="scss">
.article-details {
  flex: 1;
  width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .breadcrumb {
    width: 1140px;
    margin: 0 auto;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 0 0 45px;
  }
  .article {
    .title {
      color: #3e3a39;
      font-size: 22px;
      line-height: 22px;
      margin: 37px 0 12px 0;
      text-align: center;
      font-weight: bolder;
    }
    .subtitle {
      text-align: center;
      margin: 0px 0 32px 0;
      font-weight: bold;
      line-height: 16px;
      font-size: 16px;
    }
    .author {
      font-size: 14px;
      line-height: 14px;
      text-align: center;
      color: #595757;
      margin: 10px 0 32px 0;
    }
    .content {
      padding: 0 45px 30px;
    }
    li {
      list-style: inherit;
      margin: 0 0 0 14px;
    }
  }
}
</style>