import http from "../http/http"

// 学术文章列表
export const articleList = (params) => {
    return http({
        url: "/websiteBrand/queryLimitForWebSite",
        method: "post",
        data: params
    })
}

// 学术文章详情
export const articleDetails = (params) => {
    return http({
        url: "/websiteBrand/queryByIdForWebSite",
        method: "post",
        data: params
    })
}